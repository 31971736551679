<template>
    <a-layout id="layout-main">
        <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsiblez width="240px" class="shadow">
            <LayoutAside :collapsed="collapsed" />
        </a-layout-sider>

        <a-layout>
            <transition name="headBar">
              <a-layout-header class="head_shadow" v-show="!isHeadBar">
                  <LayoutHeader @colapsed="handlerCollspsed" />
              </a-layout-header>
            </transition>
            <!-- tabs @contextmenu.prevent禁止右键 -->
            <a-layout-header class="head_shadow_tabs" @contextmenu.prevent>
              <LayoutTabs @refreshHandle="refreshHandle" @headBarHandle="headBarHandle"/>
            </a-layout-header>
            <a-layout-content :style="{ margin: '20px 20px 0px 20px',  minHeight: '280px' }">
                <LayoutMain ref="LayoutMainRef" />
            </a-layout-content>
            <a-layout-footer style="text-align: center; margin:0;padding:0;font-size:12px;color: rgba(0, 0, 0, 0.35);height:20px;line-height: 20px;">
              湖北昱希智能制造有限公司企业资源管理系统 ©2024-{{new Date().getFullYear()}}
            </a-layout-footer>
        </a-layout>
    </a-layout>
</template>
<script>
import LayoutAside from "./components/aside/Index";
import LayoutHeader from "./components/Header";
import LayoutMain from "./components/Main";
import LayoutTabs from "./components/Tabs"
import { reactive, ref, toRefs,computed } from 'vue';
export default {
    name: "Layout",
    components: { LayoutAside, LayoutHeader, LayoutMain,LayoutTabs },
    setup(){
      const LayoutMainRef = ref();
      const data = reactive({
            selectedKeys: ['1'],
            collapsed: JSON.parse(localStorage.getItem("collapsed")),
            isHeadBar: JSON.parse(localStorage.getItem("headBar")),
      })

      const handlerCollspsed = (value) => {
            let bool = value;
            data.collapsed = bool;
            localStorage.setItem("collapsed", bool);
      }

      const callback = () =>{

      }

      const refreshHandle = () =>{
        //调用LayoutMain 组件的方法
        LayoutMainRef.value.refresh();
      }

      //是否隐藏头部
      const headBarHandle = (value) => {
        data.isHeadBar = value;
        localStorage.setItem("headBar", data.isHeadBar);
      }

      return {
            ...toRefs(data),
            handlerCollspsed,
            callback,
            refreshHandle,
            LayoutMainRef,
            headBarHandle,
      }
    }
}
</script>
<style lang="scss">
#layout-main { height: 100vh; }
.shadow{
  box-shadow: 2px 0 6px rgba(0, 21, 41, .35);
  overflow-x: hidden;
  overflow-y: auto;
}
/*滚动条样式*/
  .shadow::-webkit-scrollbar {/*滚动条整体样式*/
    width: 3px;     /*高宽分别对应横竖滚动条的尺寸*/
  }
  .shadow::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    /*border-radius: 2px;*/
    -webkit-box-shadow: inset 0 0 5px rgba(24,144,255,1);
    background: rgba(24 ,144 ,255,1);
  }
  .shadow::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.15);
    border-radius: 0;
    background: rgba(0,0,0,.15);
  }
.head_shadow{
  height: 60px;
  line-height: 60px;
  background: #fff; padding: 0;
}
.head_shadow_tabs{
  border-top: 1px solid #fafafa;
  height: 46px;
  overflow: hidden;
  background: #fff; padding: 0;box-shadow: 2px 6px 8px rgba(0,0,0,.08);
}

.headBar-enter-active,
.headBar-leave-active {
  transition: all 0.5s ease;
}

.headBar-enter-from,
.headBar-leave-to {
  margin-top: -60px;
}
</style>


