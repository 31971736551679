<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="订单编号">
                    <a-input v-model:value="data.queryData.order_id" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item label="制单人">
                    <a-input v-model:value="data.queryData.producer" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <!-- <a-form-item label="客户名称">
                    <a-input v-model:value="data.queryData.customer" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item> -->
                <a-form-item label="订单状态">
                    <a-select allow-clear
                        v-model:value="data.queryData.status"
                        placeholder=""
                        :loading="data.isQueryRole"
                        style="width:170px;"
                    >
                        <template v-for="(item) in data.statusList" :key="item.id">
                            <a-select-option :value="item.id">{{item.name}}</a-select-option>
                        </template>
                    </a-select>
                </a-form-item>
                <a-form-item label="是否报废">
                    <a-select allow-clear
                        v-model:value="data.queryData.is_deleted"
                        placeholder=""
                        style="width:200px;"
                    >
                        <a-select-option :value="0">否</a-select-option>
                        <a-select-option :value="1">是</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.see" @click="orderSee">查看</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.add" @click="orderAdd">制单</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.remove" @click="orderRemove">报废</a-button>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
                <cTable
                ref="ctableRef"
                :toTableData="data.tableData"
                :toTableY="data.tableHeight"
                :toColumns="columns"
                :toBordered="true"
                toSize="middle"
                :toTableLoading="data.tableLoading"
                toPageStyle="float: right;"
                :toDataTotal="data.dataTotal"
                @tData="tData"
                @setSortedInfo="setSortedInfo"
                @getSelectedRowKeys="getSelectedRowKeys"
                />
        </div>
    </div>

<!-- 制单 -->
<a-modal
  :title="data.modalTitle"
  v-model:visible="data.isModal"
  :confirm-loading="data.isModalLoading"
  @ok="handleModal"
  @cancel="handleCloseModal"
  :ok-button-props="{ disabled: data.isOkButton }"
  :cancel-button-props="{ disabled: data.isCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="1150px"
  :maskClosable="false" 
>
<a-spin :spinning="data.isModalLoading">
    <a-form
        ref="addModalRef"
        :model="data.addModalData"
        :rules="data.addModalRules"
        :labelCol="{span:10}"
        :wrapperCol="{span:13}"
    >
    <a-row>
        <a-col :span="10">
            <!-- @mouseenter="openDddProductIdSelect" 
                @mouseleave="colseDddProductIdSelect" 
                :open="data.isOpenDddProductIdSelect"  -->
            <a-form-item has-feedback label="添加订单产品" name="product_id" :wrapperCol="{ style: 'width: calc(100% - 110px)' }" :labelCol="{style: 'width: 110px'}">
                <a-select 
                show-search 
                optionFilterProp="label"
                v-model:value="data.addModalData.product_id" 
                placeholder="" 
                :loading="data.isProductList" 
                @select="addOrderProduct">
                    <template v-for="(item) in data.productList" :key="item">
                        <a-select-option :value="item.id" :label="item.name">{{item.name}}</a-select-option>
                    </template>
                </a-select>
            </a-form-item>
        </a-col>
        <a-col :span="6">
            <a-form-item has-feedback label="计划生产数" name="plan_quantities">
                <a-input-number style="width:100%" v-model:value="data.addModalData.plan_quantities" :precision="0" :min="1" :max="9999999" />
            </a-form-item>
        </a-col>
        <a-col :span="6">
            <a-form-item has-feedback label="计划完成日期" name="plan_date">
                <a-date-picker v-model:value="data.addModalData.plan_date" value-format="YYYY-MM-DD" format="YYYY-MM-DD" />
            </a-form-item>
        </a-col>
        <a-col :span="2">
            <a-form-item label="" name="" style="text-align: right;" :wrapperCol="{ style: 'width: calc(100% - 0px)' }" :labelCol="{style: 'width: 0px'}">
                <a-button type="primary" ghost @click="onAdd" >添加</a-button>
            </a-form-item>
        </a-col>
    </a-row>
    </a-form>
    <a-form
        ref="modalRef"
        :model="data.modalData"
        :rules="data.modalRules"
        :labelCol="{span:7}"
        :wrapperCol="{span:17}"
    >
        <div class="addTable">
            <a-table bordered :data-source="data.tableProductList" :columns="addColumns" :pagination="false" rowKey="key" >
                <template #toOperation="{ record }">
                    <a-popconfirm
                        v-if="data.tableProductList.length"
                        title="是否确定移除?"
                        @confirm="onDelete(record.key)"
                    >
                        <a>移除</a>
                    </a-popconfirm>
                </template>
                 <template #inlong="{ text }">
                    <div class="long">
                        <a-tooltip :title="text">
                            {{text}}
                        </a-tooltip>
                    </div>
                </template>
            </a-table>
        </div>
        <!-- <a-row>
            <a-col :span="6">
                <a-form-item has-feedback label="客户名称" name="customer">
                    <a-input v-model:value="data.modalData.customer" allow-clear/>
                </a-form-item>
            </a-col>
            <a-col :span="6">
                <a-form-item has-feedback label="客户联系方式" name="customer_phone" :wrapperCol="{ style: 'width: calc(100% - 106px)' }" :labelCol="{style: 'width: 106px'}">
                    <a-input v-model:value="data.modalData.customer_phone" allow-clear />
                </a-form-item>
            </a-col>
            <a-col :span="12">
                <a-form-item has-feedback label="客户地址" name="address" :wrapperCol="{ style: 'width: calc(100% - 76px)' }" :labelCol="{style: 'width: 76px'}">
                    <a-input v-model:value="data.modalData.address" allow-clear/>
                </a-form-item>
            </a-col>
        </a-row> -->
        <a-row>
            <a-col :span="24">
                <a-form-item has-feedback label="要求" name="request" :wrapperCol="{ style: 'width: calc(100% - 42px)' }" :labelCol="{style: 'width: 42px'}">
                    <a-textarea
                    v-model:value="data.modalData.request"
                    placeholder="..."
                    auto-size
                    allow-clear
                    />
                </a-form-item>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
                <a-form-item has-feedback label="备注" name="" :wrapperCol="{ style: 'width: calc(100% - 42px)' }" :labelCol="{style: 'width: 42px'}">
                    <a-textarea
                    v-model:value="data.modalData.remarks"
                    placeholder="..."
                    auto-size
                    allow-clear
                    />
                </a-form-item>
            </a-col>
        </a-row>
    </a-form>
</a-spin>
</a-modal>
<!-- 详情 -->
<a-modal
  title="订单详情"
  v-model:visible="data.isOrderDetails"
  width="1200px"
  @cancel="handleCloseOrderDetailsModal"
  @ok="handlePDF"
  okText = "导出PDF"
  :maskClosable="false"
  :confirm-loading="data.isOrderDetailsLoading"
>
<a-spin :spinning="data.isOrderDetailsLoading">
    <!-- <a-row>
        <a-col :span="6">
            <div class="detailsCol">
                订单编号：{{data.orderDetailsData.order_id}}
            </div>
        </a-col>
        <a-col :span="6">
            <div class="detailsCol">
                下单日期：{{data.orderDetailsData.gmt_create}}
            </div>
        </a-col>
        <a-col :span="6">
            <div class="detailsCol">
                制单人：{{data.orderDetailsData.producer}}
            </div>
        </a-col>
    </a-row>
    <div class="detailsTable">
        <a-table bordered :data-source="data.orderDetailsTableData" :columns="detailsColumns" :pagination="false" rowKey="id">
            <template #long="{ text }">
                    <div class="long">
                        <a-tooltip :title="text">
                            {{text}}
                        </a-tooltip>
                    </div>
            </template>
        </a-table>
    </div>
    <a-row>
        <a-col :span="6">
            <div class="detailsCol">
                客户名称：{{data.orderDetailsData.customer}}
            </div>
        </a-col>
        <a-col :span="6">
            <div class="detailsCol">
                客户联系方式：{{data.orderDetailsData.customer_phone}}
            </div>
        </a-col>
        <a-col :span="12">
            <div class="detailsCol">
                <div class="long">
                    <a-tooltip :title="data.orderDetailsData.address">
                        客户地址：{{data.orderDetailsData.address}}
                    </a-tooltip>
                </div>
            </div>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="24">
            <div class="detailsCol">
                要求：{{data.orderDetailsData.request}}
            </div>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="24">
            <div class="detailsCol">
                备注：{{data.orderDetailsData.remarks}}
            </div>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="6" v-if="data.orderDetailsData.executive">
            销售总管：<canvas id="executiveCanvas" width="250" height="250"></canvas>
        </a-col>
        <a-col :span="6" v-if="data.orderDetailsData.factory">
            厂长/副厂长：<canvas id="factoryCanvas" width="250" height="250"></canvas>
        </a-col>
        <a-col :span="6" v-if="data.orderDetailsData.chairman">
            董事长：<canvas id="chairmanCanvas" width="250" height="250"></canvas>
        </a-col>
    </a-row> -->
    <div id="printMe">
    <div class="detailsTitle">湖北昱希订单</div>
    <a-row>
        <a-col :span="6"></a-col>
        <a-col :span="6"></a-col>
        <a-col :span="6">
            <div class="detailsCol">
                订单编号：{{data.orderDetailsData.order_id}}
            </div>
        </a-col>
        <a-col :span="6">
            <div class="detailsCol">
                下单日期：{{data.orderDetailsData.gmt_create}}
            </div>
        </a-col>
    </a-row>
    <div class="detailsTable">
        <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#f0f0f0" style="border-collapse:collapse;">
            <tr>
                <td width="172" height="40"><div align="center" class="STYLE7">产品名称</div></td>
                <td width="106" height="40"><div align="center" class="STYLE7">型号</div></td>
                <td width="146"  height="40"><div align="center" class="STYLE7">重量（±0.5kg）</div></td>
                <td width="136" height="40" ><div align="center"><span class="STYLE7">尺寸（mm）</span></div></td>
                <td width="53" height="40"><div align="center" class="STYLE7">颜色</div></td>
                <td width="107" height="40"><div align="center" class="STYLE7">计量单位</div></td>
                <td width="132" height="40"><div align="center" class="STYLE7">计划生产数</div></td>
                <td width="159" height="40"><div align="center" class="STYLE7">计划完成日期</div></td>
                <td width="169" height="40"><div align="center" class="STYLE7">实际完成日期</div></td>
            </tr>
            <tr class="STYLE8" v-for="(item) in data.orderDetailsTableData" :key="item">
                <td height="32"><div class="STYLE8 td1">{{item.productName}}</div></td>
                <td height="32"><div align="center" class="STYLE8 td2">{{item.productModel}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.productWeight}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.productSize}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.productColor}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.productUnit}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.planQuantities}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{getTime(item.planDate)}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{getTime(item.completionDate)}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center">生产要求</div></td>
                <td height="40" colspan="8"><div class="td3">{{data.orderDetailsData.request}}</div></td>
            </tr>
            <!-- <tr class="STYLE7">
                <td height="40"><div align="center">物流流向（客户）</div></td>
                <td height="40" colspan="4"><div align="center" class="STYLE7">{{data.orderDetailsData.customer}}</div></td>
                <td height="40"><div align="center">联系电话</div></td>
                <td height="40" colspan="3"><div align="center" class="STYLE7">{{data.orderDetailsData.customer_phone}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center">地址</div></td>
                <td height="40" colspan="8"><div class="td3">{{data.orderDetailsData.address}}</div></td>
            </tr> -->
            <tr class="STYLE7">
                <td height="40"><div align="center" >生产部签发</div></td>
                <td height="40" colspan="4"><div align="center">{{data.orderDetailsData.factory_name}}</div></td>
                <!-- <td height="40"><div align="center" >董事长签发</div></td>
                <td height="40" colspan="3"><div align="center">{{data.orderDetailsData.chairman_name}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center">销售部负责人签</div></td>
                <td height="40" colspan="4"><div align="center">{{data.orderDetailsData.executive_name}}</div></td> -->
                <td height="40"><div align="center">制单人</div></td>
                <td height="40" colspan="3"><div align="center">{{data.orderDetailsData.producer}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="34"><div align="center" >备注</div></td>
                <td height="34" colspan="8"><div class="td3">{{data.orderDetailsData.remarks}}</div></td>
            </tr>
        </table>
    </div>
    </div>
</a-spin>
</a-modal>
</template>

<script>
import { useStore } from 'vuex'
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick } from 'vue'
import cTable from "@/components/table"
import { getList,details,add,scrap } from "@/api/order/orderAll.js"
import { downProduct } from "@/api/down.js"
import { isOK } from "@/utils/illegal.js"
import { getYyyyddmm } from "@/utils/time.js"
import { getPdf } from "@/utils/htmlToPdf.js"
import { Modal,message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
export default {
   name: "OrderAll",
   components: { cTable },
   setup(){
       const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        //页面的表格列属性
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
                {
                    title: '序号',
                    width: 60,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '订单编号',
                    width: 200,
                    dataIndex: 'orderId',
                    key: 'order_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '订单产品集合',
                    width: 200,
                    dataIndex: 'orderProductNameList',
                    key: 'orderProductNameList',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                // {
                //     title: '客户名称',
                //     width: 200,
                //     dataIndex: 'customer',
                //     key: 'customer',
                //     sorter: true,
                //     sortOrder: sorted.columnKey === 'customer' && sorted.order,
                //     sortDirections: ['descend', 'ascend'],
                //     ellipsis: true,
                //     slots: {
                //         customRender: 'long',
                //     },
                // },
                // {
                //     title: '客户手机号码',
                //     width: 120,
                //     dataIndex: 'customerPhone',
                //     key: 'customer_phone',
                //     ellipsis: true,
                // },
                // {
                //     title: '客户地址',
                //     width: 200,
                //     dataIndex: 'address',
                //     key: 'address',
                //     ellipsis: true,
                //     slots: {
                //         customRender: 'long',
                //     },
                // },
                {
                    title: '制单人',
                    width: 100,
                    dataIndex: 'producer',
                    key: 'producer',
                    ellipsis: true,
                },
                {
                    title: '订单状态',
                    width: 140,
                    dataIndex: 'status',
                    key: 'status',
                    customRender: (text) => {
                        let v = "";
                        switch(text.record.status){
                            case 0: v = "厂长审核中"; break;
                            case 1: v = "生产中"; break;
                            case 2: v = "完成"; break;
                            default:"";      
                        }
                        return v;
                    },
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '创建时间',
                    width: 180,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                },
                {
                    title: '是否报废',
                    width: 90,
                    dataIndex: 'isDeleted',
                    key: 'is_deleted',
                    ellipsis: true,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'is_deleted' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    slots: {
                        customRender: 'isDeleted',
                    },
                },
                {
                    title: '要求或建议',
                    width: 200,
                    dataIndex: 'request',
                    key: 'request',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '备注',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
            ];
        });
        //制单的产品添加的表格列属性
        const addColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    width: 120,
                    dataIndex: 'productName',
                    key: 'product_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品型号',
                    width: 110,
                    dataIndex: 'productModel',
                    key: 'product_model',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '尺寸',
                    width: 140,
                    dataIndex: 'productSize',
                    key: 'product_size',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品重量(kg)',
                    width: 140,
                    dataIndex: 'productWeight',
                    key: 'product_weight',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品颜色',
                    width: 100,
                    dataIndex: 'productColor',
                    key: 'product_color',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '计量单位',
                    width: 100,
                    dataIndex: 'productUnit',
                    key: 'product_unit',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '计划生产数',
                    width: 120,
                    dataIndex: 'planQuantities',
                    key: 'plan_quantities',
                    ellipsis: true,
                },
                {
                    title: '计划完成日期',
                    width: 140,
                    dataIndex: 'planDate',
                    key: 'plan_date',
                    ellipsis: true,
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    ellipsis: true,
                    slots: {
                        customRender: 'toOperation',
                    },
                },
            ]
        })
        //订单详情的产品列表列属性
        const detailsColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 65,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    width: 120,
                    dataIndex: 'productName',
                    key: 'product_name',
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品型号',
                    width: 90,
                    dataIndex: 'productModel',
                    key: 'product_model',
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '尺寸',
                    width: 120,
                    dataIndex: 'productSize',
                    key: 'product_size',
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品重量(kg)',
                    width: 120,
                    dataIndex: 'productWeight',
                    key: 'product_weight',
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品颜色',
                    width: 90,
                    dataIndex: 'productColor',
                    key: 'product_color',
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '计量单位',
                    width: 90,
                    dataIndex: 'productUnit',
                    key: 'product_unit',
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '计划生产数',
                    width: 110,
                    dataIndex: 'planQuantities',
                    key: 'plan_quantities',
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '计划完成日期',
                    width: 140,
                    dataIndex: 'planDate',
                    key: 'plan_date',
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '实际完成日期',
                    dataIndex: 'completionDate',
                    key: 'completion_date',
                    slots: {
                        customRender: 'long',
                    },
                },
            ]
        })
        const data = reactive({
            styleTableHeight:"height:calc(100% - 104px)",
            btnMenu: store.state.user.btnList.orderAll,
            queryData:{
                order_id:"",
                producer:"",
                // customer:"",
                status:"",
                is_deleted:0,
            },
            tableLoading: false,
            tableData: [],
            tableHeight: 0,
            selectedRowKeys: [],
            dataTotal:0,
            statusList:[
                {id:0,name:"厂长审核中"},
                {id:1,name:"生产中"},
                {id:2,name:"完成"},
            ],
            isOpenDddProductIdSelect:false,

            //以下是增删改查按钮事件参数 
            modalTitle:"",
            modalType:"",
            isModal: false,
            isModalLoading: false,
            isOkButton: false,
            isCancelButton: false,
            isProductList:false,//加载产品下拉
            productList:[],//产品下拉
            selectProduct:{},//选中的产品
            tableProductList:[],//选中的产品table列表
            addModalData:{
                product_id:"",
                plan_quantities:"",
                plan_date:"",
            },
            addModalRules:{
                product_id:[{ required: true, message: '请选择产品', trigger: 'change' }],
                plan_quantities:[{ required: true,type:'number', message: '计划生产数不能为空', trigger: 'blur' }],
                plan_date:[{ required: true, message: '计划完成日期不能为空', trigger: 'change' }],
            },
            modalData:{
                // customer:"",
                // customer_phone:"",
                // address:"",
                request:"",
                remarks:"",
            },
            modalRules:{
                // customer:[{ required: true, message: '客户名称不能为空', trigger: 'blur' }],
                // customer_phone:[
                //     { required: true, message: '手机号码不能为空', trigger: 'blur' },
                //     { min: 11, max: 11, message: '手机号码为11位数字', trigger: 'blur' },
                //     {
                //         validator:(rule,value,callback)=>{
                //             const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
                //             if(!regMobile.test(value)){
                //                 callback(new Error('手机号码不正确'))
                //             }else{
                //                 callback( )
                //             }
                //         },
                //         trigger:'blur'
                //     }
                // ],
                // address:[{ required: true, message: '客户地址不能为空', trigger: 'blur' }],
            },

            //以下为订单详情参数
            isOrderDetails:false,
            isOrderDetailsLoading:false,
            orderDetailsData:{
                order_id:"",
                gmt_create:"",
                producer:"",
                // customer:"",
                // customer_phone:"",
                // address:"",
                request:"",
                remarks:"",
                executive:false,
                executive_id:"",
                executive_name:"",
                factory:false,
                factory_id:"",
                factory_name:"",
                chairman:false,
                chairman_id:"",
                chairman_name:"",
            },
            orderDetailsTableData:[]
        });
        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                sort: cont[1].sort,
                order_id: isOK(cont[0].order_id)?cont[0].order_id:"",
                producer: isOK(cont[0].producer)?cont[0].producer:"",
                // customer: isOK(cont[0].customer)?cont[0].customer:"",
                status: isOK(cont[0].status)?cont[0].status:"",
                is_deleted: isOK(cont[0].is_deleted)?cont[0].is_deleted:"",
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            data.queryData.order_id = "";
            data.queryData.producer = "";
            // data.queryData.customer = "";
            data.queryData.status = "";
            data.queryData.is_deleted = 0;
            //清空表格复选框选中状态
            ctableRef.value.unSelectedRowKeys();
            toQueryList(true);
        }

        //渲染后 加载数据
        onMounted(() => {
            //加载表格数据
            toQueryList(true);

            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })
        });
        //重置添加的数据
        const resetAddTableData = ()=>{
            data.addModalData={
                product_id:"",
                plan_quantities:"",
                plan_date:undefined,
            }
        }
        //重置modal数据
        const resetModalData = () =>{
            data.modalData = {
                // customer:"",
                // customer_phone:"",
                // address:"",
                request:"",
                remarks:"",
            };
        }

        //获取产品下拉数据
        const getDownProductList = ()=>{
            data.isProductList = true;
            downProduct().then(res =>{
                data.productList = res.data.list;
                data.isProductList = false;
            })
        }

        //制单-产品选中
        const addOrderProduct = (value)=>{
            data.selectProduct = {};
            for(let i = 0; i < data.productList.length; i++){
                if(data.productList[i].id == value){
                    data.selectProduct = data.productList[i];
                    break;
                }
            }
        }
        //制单-添加产品
        const addModalRef = ref();
        const onAdd = ()=>{
            addModalRef.value.validate().then(() => {
                const newData = {
                    key: Date.now()+"",
                    productId: data.selectProduct.id,
                    productName: data.selectProduct.name || "",
                    productModel: data.selectProduct.model || "",
                    productSize: data.selectProduct.size || "",
                    productWeight: data.selectProduct.weight || "",
                    productColor: data.selectProduct.color || "",
                    productUnit: data.selectProduct.unit || "",
                    planQuantities: data.addModalData.plan_quantities || "",
                    planDate: data.addModalData.plan_date || "",
                };
                let isRepeat = false;//是否已经存在
                for(let i = 0;i<data.tableProductList.length;i++){
                    if(newData.productId===data.tableProductList[i].productId){
                        isRepeat = true;
                    }
                }
                if(isRepeat){
                    //已经存在该商品
                    message.warning("已经添加过该产品");
                }else{
                    //不存在添加
                    data.tableProductList.push(newData);
                }
                
                resetAddTableData();
                //重置表单校验
                addModalRef.value.resetFields();
            });
        }
        //制单-删除选中
        const onDelete = key => {
            data.tableProductList = data.tableProductList.filter(item => item.key !== key);
        };

        //按钮-制单
        const modalRef = ref();
        const orderAdd = ()=>{
            data.modalTitle = "制单";
            data.modalType = "add";
            //加载角色下拉
            getDownProductList();
            resetModalData();
            data.isModal = true;
        }

        //按钮-查询
        const orderSee = ()=>{
            data.modalTitle = "查看订单";
            //判断是否选择一条数据
            if(data.selectedRowKeys.length==1){
                data.isOrderDetailsLoading = true;
                details({id:data.selectedRowKeys[0]}).then(res =>{
                    data.isOrderDetails = true;
                    //订单产品列表
                    data.orderDetailsTableData = res.data.orderProduct;
                    //订单数据
                    data.orderDetailsData.order_id = res.data.order.orderId;
                    data.orderDetailsData.gmt_create = res.data.order.gmtCreate;
                    data.orderDetailsData.producer  = res.data.order.producer || "";
                    // data.orderDetailsData.customer = res.data.order.customer || "";
                    // data.orderDetailsData.customer_phone = res.data.order.customerPhone || "";
                    // data.orderDetailsData.address = res.data.order.address || "";
                    data.orderDetailsData.request = res.data.order.request || "";
                    data.orderDetailsData.remarks = res.data.order.remarks || "";
                    data.orderDetailsData.executive = res.data.order.executive;
                    if(data.orderDetailsData.executive){
                        data.orderDetailsData.executive_id = res.data.order.executiveId || "";
                        data.orderDetailsData.executive_name = res.data.order.executiveName || "";
                        // getSeal("executiveCanvas","台州市昱希塑业有限公司",data.orderDetailsData.executive_name);
                    }
                    
                    data.orderDetailsData.factory = res.data.order.factory;
                    if(data.orderDetailsData.factory){
                        data.orderDetailsData.factory_id = res.data.order.factoryId || "";
                        data.orderDetailsData.factory_name = res.data.order.factoryName || "";
                        // getSeal("factoryCanvas","台州市昱希塑业有限公司",data.orderDetailsData.factory_name);
                    }

                    data.orderDetailsData.chairman = res.data.order.chairman;
                    if(data.orderDetailsData.chairman){
                        data.orderDetailsData.chairman_id = res.data.order.chairmanId || "";
                        data.orderDetailsData.chairman_name = res.data.order.chairmanName || "";
                        // getSeal("factoryCanvas","台州市昱希塑业有限公司",data.orderDetailsData.chairman_name);
                    }
                    
                    data.isOrderDetailsLoading = false;
                }).catch((err) => {
                    data.isOrderDetailsLoading = false;
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时查询多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //工具-时间格式化
        const getTime = (time) =>{
            return getYyyyddmm(time);
        }

        //对话框-确定
        const handleModal = ()=>{
            // console.log(data.tableProductList.length)
            // modalRef.value.validate().then(() => {
                if(data.tableProductList.length>0){
                    if(data.modalType == "add"){
                        data.isModalLoading = true;
                        data.tableProductList = data.tableProductList.map((item, index) => {
                            return {
                                productId: item.productId,
                                productName: item.productName,
                                productModel: item.productModel,
                                productSize: item.productSize,
                                productWeight: item.productWeight,
                                productColor: item.productColor,
                                productUnit: item.productUnit,
                                planQuantities: item.planQuantities,
                                planDate: item.planDate,
                            }
                        })
                        add({
                            orderProductList:data.tableProductList,
                            // customer:data.modalData.customer,
                            // customer_phone:data.modalData.customer_phone,
                            // address:data.modalData.address,
                            request:data.modalData.request,
                            remarks:data.modalData.remarks,
                        }).then(res =>{
                            message.success(res.data.sms);
                            handleCloseModal();
                            resetTable();
                        }).catch((err) => {
                            data.isModalLoading = false;
                        });
                    }
                }else{
                    message.warning("请添加订单产品");
                }
            // })
        }

        //对话框-取消
        const handleCloseModal = () =>{
            resetAddTableData();
            resetModalData();
            data.tableProductList = [];
            data.isModal = false;
            data.isModalLoading = false;
            data.isOkButton = false;
            //重置表单校验
            modalRef.value.resetFields();
            //重置表单校验
            addModalRef.value.resetFields();
        }

        //订单详情对话框-取消
        const handleCloseOrderDetailsModal = ()=>{
            data.isOrderDetails = false;
            data.isOrderDetailsLoading = false;
            data.orderDetailsData={
                order_id:"",
                gmt_create:"",
                producer:"",
                // customer:"",
                // customer_phone:"",
                // address:"",
                request:"",
                remarks:"",
                executive:false,
                executive_id:"",
                executive_name:"",
                factory:false,
                factory_id:"",
                factory_name:"",
                chairman:false,
                chairman_id:"",
                chairman_name:"",
            }
            data.orderDetailsTableData=[]
        }

        //详情-导出PDF
        const handlePDF = ()=>{
            getPdf("printMe","订单")
        }

        //按钮-报废
        const orderRemove = ()=>{
            if(data.selectedRowKeys.length==1){
                Modal.confirm({
                        title: '报废订单',
                        icon: createVNode(ExclamationCircleOutlined),
                        content: '您是否确定报废订单',
                        okText: '确认',
                        cancelText: '取消',
                        //确定
                        onOk() {
                            scrap({id:data.selectedRowKeys[0]}).then(res =>{
                                message.success("报废成功");
                                resetTable();
                            })
                        },
                        // 取消
                        onCancel() {},
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时修改多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        // //制单窗口 鼠标移动到产品下拉时，自动打开下拉 开
        // const openDddProductIdSelect = ()=>{
        //     data.isOpenDddProductIdSelect = true;
        // }

        // //制单窗口 鼠标移动到产品下拉时，自动打开下拉 关
        // const colseDddProductIdSelect = ()=>{
        //     data.isOpenDddProductIdSelect = false;
        // }

        return {
            setSortedInfo,
            columns,
            addColumns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,

            getDownProductList,
            addOrderProduct,
            onAdd,
            onDelete,

            resetAddTableData,

            resetModalData,
            addModalRef,
            modalRef,
            orderAdd,
            orderSee,
            handleModal,
            handleCloseModal,

            detailsColumns,
            handleCloseOrderDetailsModal,
            handlePDF,

            getTime,

            orderRemove,

            // openDddProductIdSelect,
            // colseDddProductIdSelect,
        }
   }
   
}
</script>
<style lang='scss' scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
.btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}
.detailsCol{
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: right;
}
.addTable{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.STYLE7 {font-size: 16px; font-family: "宋体"; }
.STYLE8 {font-size: 15px; font-family: "宋体"; }
.td1{
	width:172px;
	word-wrap:break-word;
	word-break:break-all;
	text-align:center;
}
.td2{
	width:106px;
	word-wrap:break-word;
	word-break:break-all;
	text-align:center;
}
.td3{
	width:100%;
    font-size: 16px;
    text-align: left;
    padding-left:5px;
	word-wrap:break-word;
	word-break:break-all;
	text-align:left;
}
.detailsTitle{
    width: 100%;
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
}
.long{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
</style>