<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="订单编号">
                    <a-input v-model:value="data.queryData.order_id" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item label="制单人">
                    <a-input v-model:value="data.queryData.producer" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.see" @click="orderProductionSee">查看</a-button>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
                <cTable
                ref="ctableRef"
                :toTableData="data.tableData"
                :toTableY="data.tableHeight"
                :toColumns="columns"
                :toBordered="true"
                toSize="middle"
                :toTableLoading="data.tableLoading"
                toPageStyle="float: right;"
                :toDataTotal="data.dataTotal"
                @tData="tData"
                @setSortedInfo="setSortedInfo"
                @getSelectedRowKeys="getSelectedRowKeys"
                />
        </div>
    </div>

<!-- 详情 -->
<a-modal
  title="查看"
  v-model:visible="data.isOrderDetails"
  width="1200px"
  @cancel="handleCloseModal"
  :maskClosable="false"
  :confirm-loading="data.isOrderDetailsLoading"
  :footer="footer"
>
<a-spin :spinning="data.isOrderDetailsLoading">
    <div class="detailsTitle">湖北昱希订单</div>
    <a-row>
        <a-col :span="6"></a-col>
        <a-col :span="6"></a-col>
        <a-col :span="6">
            <div class="detailsCol">
                订单编号：{{data.orderDetailsData.order_id}}
            </div>
        </a-col>
        <a-col :span="6">
            <div class="detailsCol">
                下单日期：{{data.orderDetailsData.gmt_create}}
            </div>
        </a-col>
    </a-row>
    <div class="detailsTable">
        <a-table bordered :data-source="data.orderDetailsTableData" :columns="detailsColumns" :pagination="false" rowKey="id">
            <template #planDate="{ text }">
                {{getTime(text)}}
            </template>
            <template #long="{ text }">
                    <div class="long">
                        <a-tooltip :title="text">
                            {{text}}
                        </a-tooltip>
                    </div>
            </template>
        </a-table>
    </div>
    <div style="margin-top:-1px;">
        <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#f0f0f0" style="border-collapse:collapse;">
            <tr class="STYLE7">
                <td height="40" width="240px"><div align="center">生产要求</div></td>
                <td height="40" colspan="8"><div class="td3">{{data.orderDetailsData.request}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40" width="240px"><div align="center" >生产部签发</div></td>
                <td height="40" colspan="4"><div align="center">{{data.orderDetailsData.factory_name!=""?data.orderDetailsData.factory_name:" "}}</div></td>
                <td height="40" width="240px"><div align="center" >董事长签发</div></td>
                <td height="40" colspan="3"><div align="center">{{data.orderDetailsData.chairman_name!=""?data.orderDetailsData.chairman_name:" "}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40" width="240px"><div align="center">销售部负责人签</div></td>
                <td height="40" colspan="4"><div align="center">{{data.orderDetailsData.executive_name!=""?data.orderDetailsData.executive_name:" "}}</div></td>
                <td height="40" width="240px"><div align="center">制单人</div></td>
                <td height="40" colspan="3"><div align="center">{{data.orderDetailsData.producer!=""?data.orderDetailsData.producer:" "}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="34"><div align="center" >备注</div></td>
                <td height="34" colspan="8"><div class="td3">{{data.orderDetailsData.remarks}}</div></td>
            </tr>
        </table>
    </div>
</a-spin>
    <template #footer>
        <a-button key="back" @click="handleCloseModal">取消</a-button>
    </template>
</a-modal>
</template>
<script>
import { useStore } from 'vuex'
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick } from 'vue'
import cTable from "@/components/table"
import { getList,details } from "@/api/warehouse/orderProduction.js"
import { isOK } from "@/utils/illegal.js"
import { message } from 'ant-design-vue';
import { getYyyyddmm } from "@/utils/time.js"
import { CheckOutlined, EditOutlined } from '@ant-design/icons-vue';
import { cloneDeep } from 'lodash-es';
export default {
   name: "OrderProduction",
   components: { cTable,CheckOutlined, EditOutlined },
   setup(){
       const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        //页面的表格列属性
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
                {
                    title: '序号',
                    width: 60,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '订单编号',
                    width: 160,
                    dataIndex: 'orderId',
                    key: 'order_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '制单人',
                    width: 100,
                    dataIndex: 'producer',
                    key: 'producer',
                    ellipsis: true,
                },
                {
                    title: '创建时间',
                    width: 180,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                },
                {
                    title: '要求或建议',
                    width: 200,
                    dataIndex: 'request',
                    key: 'request',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '备注',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
            ];
        });
        //审核 订单产品的表格列属性
        const detailsColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    width: 170,
                    dataIndex: 'productName',
                    key: 'product_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品型号',
                    width: 100,
                    dataIndex: 'productModel',
                    key: 'product_model',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '尺寸',
                    width: 140,
                    dataIndex: 'productSize',
                    key: 'product_size',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品重量(kg)',
                    width: 120,
                    dataIndex: 'productWeight',
                    key: 'product_weight',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品颜色',
                    width: 90,
                    dataIndex: 'productColor',
                    key: 'product_color',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '计量单位',
                    width: 90,
                    dataIndex: 'productUnit',
                    key: 'product_unit',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '计划生产数',
                    width: 110,
                    dataIndex: 'planQuantities',
                    key: 'plan_quantities',
                    ellipsis: true,
                },
                {
                    title: '计划完成日期',
                    width: 120,
                    dataIndex: 'planDate',
                    key: 'plan_date',
                    ellipsis: true,
                    slots: {
                        customRender: 'planDate',
                    },
                },
                {
                    title: '实际完成日期',
                    dataIndex: 'completionDate',
                    key: 'completion_date',
                    ellipsis: true,
                    slots: {
                        customRender: 'planDate',
                    },
                }
                
            ]
        })
        const data = reactive({
            styleTableHeight:"height:calc(100% - 102px)",
            btnMenu: store.state.user.btnList.orderProduction,
            queryData:{
                order_id:"",
                producer:"",
            },
            tableLoading: false,
            tableData: [],
            tableHeight: 0,
            selectedRowKeys: [],
            dataTotal:0,

            //以下为订单详情参数 导出显示用
            isOrderDetails:false,
            isOrderDetailsLoading:false,
            orderDetailsData:{
                order_id:"",
                gmt_create:"",
                producer:"",
                request:"",
                remarks:"",
                executive:"",
                executive_id:"",
                executive_name:"",
                factory:"",
                factory_id:"",
                factory_name:"",
                chairman:"",
                chairman_id:"",
                chairman_name:"",
            },
            orderDetailsTableData:[],

        });
        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                sort: cont[1].sort,
                order_id: isOK(cont[0].order_id)?cont[0].order_id:"",
                producer: isOK(cont[0].producer)?cont[0].producer:"",
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            data.queryData.order_id = "";
            data.queryData.producer = "";
            //清空表格复选框选中状态
            ctableRef.value.unSelectedRowKeys();
            toQueryList(true);
        }

        //渲染后 加载数据
        onMounted(() => {
            //加载表格数据
            toQueryList(true);

            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })
        });

        //工具-时间格式化
        const getTime = (time) =>{
            return getYyyyddmm(time);
        }

        //按钮-查看
        const orderProductionSee = ()=>{
            //判断是否选择一条数据
            if(data.selectedRowKeys.length==1){
                data.isOrderDetailsLoading = true;
                details({id:data.selectedRowKeys[0]}).then(res =>{
                    data.isOrderDetails = true;
                    //订单产品列表
                    data.orderDetailsTableData = res.data.orderProduct;
                    //订单数据
                    data.orderDetailsData.order_id = res.data.order.orderId;
                    data.orderDetailsData.gmt_create = res.data.order.gmtCreate;
                    data.orderDetailsData.producer  = res.data.order.producer || "";
                    data.orderDetailsData.request = res.data.order.request || "";
                    data.orderDetailsData.remarks = res.data.order.remarks || "";
                    data.orderDetailsData.executive = res.data.order.executive;
                    if(data.orderDetailsData.executive){
                        data.orderDetailsData.executive_id = res.data.order.executiveId || "";
                        data.orderDetailsData.executive_name = res.data.order.executiveName || "";
                        // getSeal("executiveCanvas","台州市昱希塑业有限公司",data.orderDetailsData.executive_name);
                    }
                    
                    data.orderDetailsData.factory = res.data.order.factory;
                    if(data.orderDetailsData.factory){
                        data.orderDetailsData.factory_id = res.data.order.factoryId || "";
                        data.orderDetailsData.factory_name = res.data.order.factoryName || "";
                        // getSeal("factoryCanvas","台州市昱希塑业有限公司",data.orderDetailsData.factory_name);
                    }

                    data.orderDetailsData.chairman = res.data.order.chairman;
                    if(data.orderDetailsData.chairman){
                        data.orderDetailsData.chairman_id = res.data.order.chairmanId || "";
                        data.orderDetailsData.chairman_name = res.data.order.chairmanName || "";
                        // getSeal("factoryCanvas","台州市昱希塑业有限公司",data.orderDetailsData.chairman_name);
                    }
                    
                    data.isOrderDetailsLoading = false;
                }).catch((err) => {
                    data.isOrderDetailsLoading = false;
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时查询多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //按钮-查看-取消
        const handleCloseModal = ()=>{
            data.isOrderDetails=false;
            data.isOrderDetailsLoading=false;
            data.orderDetailsData={
                order_id:"",
                gmt_create:"",
                producer:"",
                request:"",
                remarks:"",
                executive:"",
                executive_id:"",
                executive_name:"",
                factory:"",
                factory_id:"",
                factory_name:"",
                chairman:"",
                chairman_id:"",
                chairman_name:"",
            }
            data.orderDetailsTableData = [];
        }

        return {
            setSortedInfo,
            columns,
            detailsColumns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,

            getTime,

            orderProductionSee,
            handleCloseModal,
        }
   }
}
</script>
<style lang="scss" scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
.btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}
.detailsTitle{
    width: 100%;
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
}
.detailsCol{
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: right;
}
.STYLE7 {
    font-size: 16px; 
    font-family: "宋体";
}
.td3{
	width:100%;
    font-size: 16px;
    text-align: left;
    padding-left:5px;
	word-wrap:break-word;
	word-break:break-all;
	text-align:left;
}
.detailsTable{
    width: 100%;
}
</style>
